<template>
    <div class="main detailBox" :style="{ backgroundImage: `url(${playerBag})` }">
        <div class="playerContent">
            <!-- 导航 -->
            <page-head class="nav_back" :src="require('@/assets/images/common/nav-back-black.svg')"></page-head>
            <iframe width="100%" class="iframeDom" ref="iframeRefDom" id="iframeDom" :src="detailUrl"
                    frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, ref, watch ,onDeactivated} from 'vue';
import store from "../../store";
import {useStore} from "vuex";

export default defineComponent({
    setup() {
        const route = useRoute()
        const router = useRouter()
        const {proxy} = getCurrentInstance();
        const store = useStore()
        const {playerBag, color} = proxy.$Config.UI_CONFIG
        const {publicWedUrl, cmsUrl} = proxy.$Config

        const state = reactive({
            playerBag,
            iframeRefDom: '',
            detailUrl: publicWedUrl + '/teens-camp/final-report',
        })

        state.detailUrl = state.detailUrl +
            `?id=${store.state.teamData.id}&xTenantId=${store.state.tenantData.id}&token=${store.state.token}&api=${cmsUrl}/managepilot`

        // 接受事件
        const handleMessage = (event)=> {
            //接收到改变时间的事件进行切换数据改变

            console.log(event.data,'event.data')
            // if(event.data.code === 'changeDate'){
            //     switchDate(event.data)
            // }
        };
        onMounted(() => {
            window.addEventListener('message', handleMessage);
        });
        onDeactivated(()=>{
            window.removeEventListener('message', handleMessage);
        })

        return {
            ...toRefs(state),

        }
    }
})
</script>

<style scoped lang="scss">

.detailBox {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    position: relative;
    box-sizing: border-box;
    padding: 60px 40px;

    .playerContent {
        position: relative;
        border-radius: 20px;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: #f3f3f3;
        /*返回按钮*/
        .nav_back {
            width: calc(100% - 64px);
            position: absolute;
            top: 32px;
            left: 32px;
            z-index: 3;
            padding: 0;
        }

        .iframeDom {
            margin-top: 110px;
            height: calc(100% - 110px);
        }
    }
}
</style>
